// eslint-disable-next-line no-unused-vars, object-curly-newline
import { computed, getCurrentInstance, readonly, ref, toRaw, watch } from '@vue/composition-api'

export default function useItemCategoriesList() {
  const vm = getCurrentInstance().proxy

  const itemCategoryListTable = ref([])

  const tableColumns = [
    { text: 'CODE', value: 'code', width: '20%' },
    { text: 'NAME', value: 'displayName', width: '30%' },
    { text: 'PARENT CODE', value: 'parentCode', width: '20%' },
    { text: 'PARENT NAME', value: 'parentDisplayName', width: '20%' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
      width: '10%',
    },
  ]

  const itemCategories = computed({
    get: () => vm.$store.getters['itemCategories/items'],
  })
  const loading = computed({
    get: () => vm.$store.getters['itemCategories/loading'],
  })
  const meta = computed({
    get: () => vm.$store.getters['itemCategories/meta'],
  })
  const filters = computed({
    get: () => vm.$store.getters['itemCategories/filters'],
  })
  const options = computed({
    get: () => vm.$store.getters['itemCategories/options'],
    set: val => vm.$store.dispatch('itemCategories/setOptions', { ...options.value, ...val }),
  })
  const search = computed({
    get: () => vm.$store.getters['itemCategories/search'],
    set: val => vm.$store.dispatch('itemCategories/setSearch', val),
  })
  const totalItemCategoryListTable = computed({
    get: () => vm.$store.getters['itemCategories/meta'].total,
  })

  const fetchItems = () => vm.$store.dispatch('itemCategories/fetchItems')
  const setOptions = val => vm.$store.dispatch('itemCategories/setOptions', val)
  const setFilters = val => vm.$store.dispatch('itemCategories/setFilters', val)

  const init = () => {
    fetchItems()
  }

  const destroy = () => {}

  return {
    itemCategoryListTable,
    tableColumns,

    totalItemCategoryListTable,
    loading,

    fetchItems,

    itemCategories,
    meta,
    search,

    options,
    filters,

    setOptions,
    setFilters,

    init,
    destroy,
  }
}
