<template>
  <div id="item-list">
    <!-- user total card -->
    <!--<v-row class="mb-5">
      <v-col
        v-for="total in itemTotalLocal"
        :key="total.total"
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ total.title }}</span>
            </div>

            <v-avatar
              :color="resolveItemTotalIcon(total.title).color"
              :class="`v-avatar-light-bg ${resolveItemTotalIcon(total.title).color}--text`"
            >
              <v-icon
                size="25"
                :color="resolveItemTotalIcon(total.title).color"
                class="rounded-0"
              >
                {{ resolveItemTotalIcon(total.title).icon }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row> -->

    <!-- list filters -->
    <v-card>
      <v-card-title>
        Search &amp; Filter
      </v-card-title>
      <v-row class="px-2 ma-0">
        <!-- role filter -->
        <!-- <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="roleFilter"
            placeholder="Select Role"
            :items="roleOptions"
            item-text="title"
            item-value="value"
            outlined
            clearable
            dense
            hide-details
          ></v-select>
        </v-col> -->

        <!-- plan filter -->
        <!-- <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="planFilter"
            placeholder="Select Plan"
            :items="planOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col> -->

        <!-- status filter -->
        <!-- <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="statusFilter"
            placeholder="Select Status"
            :items="statusOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col> -->
      </v-row>

      <!-- <v-divider class="mt-4"></v-divider> -->

      <!-- actions -->
      <!-- <v-card-text class="d-flex align-center flex-wrap pb-0">
        <table-search
          v-model="options.q"
          dense
          outlined
          hide-details
          full-width
        />

        <v-spacer></v-spacer>
      </v-card-text> -->

      <!-- table -->
      <vuex-table
        :headers="tableColumns"
        :items="itemCategories"
        :options="options"
        :search.sync="search"
        :meta.sync="meta"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [5, 10, 25, 50, 100]
        }"
        @update:options="o => options = o"
      >
        <!-- name -->
        <template #[`item.displayName`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'item-category-view', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.displayName }}
              </router-link>
            </div>
          </div>
        </template>

        <!-- parent code -->
        <template #[`item.parentCode`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'item-category-view', params : { id : item.parentId } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.parentCode }}
              </router-link>
            </div>
          </div>
        </template>

        <!-- parent name -->
        <template #[`item.parentDisplayName`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'item-category-view', params : { id : item.parentId } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.parentDisplayName }}
              </router-link>
            </div>
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                :to="{ name: 'item-category-view', params: { id: item.id } }"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiEyeOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>View Category</span>
          </v-tooltip>
        </template>
      </vuex-table>
    </v-card>
  </div>
</template>

<script>
import { onMounted, onUnmounted } from '@vue/composition-api'
import { mdiEyeOutline, mdiExportVariant } from '@mdi/js'
import useItemCategoryList from './useItemCategoryList'

export default {
  setup() {
    const {
      itemCategoryListTable,
      tableColumns,
      roleFilter,
      planFilter,
      statusFilter,
      totalItemCategoryListTable,
      loading,

      resolveItemTotalIcon,

      itemCategories,
      meta,

      options,
      filters,
      search,

      init,
      destroy,
    } = useItemCategoryList()

    onMounted(() => {
      init()
    })
    onUnmounted(() => {
      destroy()
    })

    const roleOptions = [
      { title: 'Admin', value: 'admin' },
      { title: 'User', value: 'user' },
    ]

    return {
      itemCategoryListTable,
      tableColumns,
      roleFilter,
      planFilter,
      statusFilter,
      totalItemCategoryListTable,
      roleOptions,

      loading,

      resolveItemTotalIcon,

      itemCategories,
      meta,

      options,
      filters,
      search,

      icons: {
        mdiEyeOutline,
        mdiExportVariant,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
